<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<ngx-slick-carousel
  class="carousel Modern-Slider"
  #slickModal="slick-carousel"
  [config]="slideConfigCarousel"
>
  <div ngxSlickItem class="slide item">
    <div class="img-fill">
      <div
        class="info"
        style="
          background-image: url(assets/images/slick_carousel_demo/Slider_01_02.jpg);
        "
      >
        <div class="container">
          <div class="info_center">
            <h5>{{ "Timeless Elegance Awaits" }}</h5>
            <h2>{{ "Discover Extraordinary" }} <br />{{ "Jewelry Pieces" }}</h2>
            <!-- <a href="/shop">
              <button class="btn btn-secondary swipe-to-top">
                {{ "Shop Now" }}
              </button>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div ngxSlickItem class="slide item">
    <div class="img-fill">
      <div
        class="info"
        style="
          background-image: url(assets/images/slick_carousel_demo/Slider_01_01.jpg);
        "
      >
        <div class="container">
          <div class="info_left">
            <h5>{{ "Designed to Perfection" }}</h5>
            <h2>
              {{ "Adorn Yourself in Luxury" }}<br />{{ "with Our Jewelry" }}
            </h2>
            <!-- <a href="/shop">
              <button class="btn btn-secondary swipe-to-top">
                {{ "Shop Now" }}
              </button>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div ngxSlickItem class="slide item">
    <div class="img-fill">
      <div
        class="info"
        style="
          background-image: url(assets/images/slick_carousel_demo/Slider_01_03.jpg);
        "
      >
        <div class="container">
          <div class="info_right">
            <h5>{{ "Exclusive Designs, Pure Beauty" }}</h5>
            <h2>{{ "Rent from Our" }}<br />{{ "Collection Today" }}</h2>
            <!-- <a href="/shop">
              <button class="btn btn-secondary swipe-to-top">
                {{ "Shop Now" }}
              </button>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ngx-slick-carousel>

<!-- Banners -->
<div class="banners-content">
  <!-- //banner twelve -->
  <div class="banner-twelve">
    <div class="container-fluid">
      <div class="group-banners">
        <div class="row">
          <div class="col-12 col-sm-12 col-lg-3">
            <figure class="banner-image">
              <div class="captions">
                <!-- <a> -->
                <img
                  class="img-fluid"
                  src="assets/images/banners/banner_2.jpg"
                  alt="Banner Image"
                />
                <div class="banner-caption left-caption">
                  <h2>
                    {{ "Necklaces" }}<small>{{ "Elegant Designs" }}</small>
                  </h2>
                </div>
                <!-- </a> -->
              </div>
            </figure>

            <figure class="banner-image mb0-image mt-image">
              <div class="captions">
                <!-- <a routerLink="/shop"> -->
                <img
                  class="img-fluid"
                  src="assets/images/banners/banner_4.jpg"
                  alt="Banner Image"
                />
                <div class="banner-caption left-caption">
                  <h2>
                    {{ "Rings" }}<small>{{ "Timeless Creations" }}</small>
                  </h2>
                </div>
                <!-- </a> -->
              </div>
            </figure>
          </div>
          <div class="col-12 col-sm-12 col-lg-6">
            <figure class="banner-image">
              <div class="captions">
                <img
                  class="img-fluid"
                  src="assets/images/banners/banner_1.jpg"
                  alt="Banner Image"
                />
                <div class="banner-caption left-caption">
                  <h2>
                    {{ "Bracelets"
                    }}<small>{{ "Stylish Ornamentations" }}</small>
                  </h2>
                  <a
                    routerLink="/shop"
                    class="btn btn-secondary swipe-to-top"
                    >{{ "Shop Now" }}</a
                  >
                </div>
              </div>
            </figure>
          </div>

          <div class="col-12 col-sm-12 col-lg-3">
            <figure class="banner-image">
              <div class="captions">
                <!-- <a routerLink="/shop"> -->
                <img
                  class="img-fluid"
                  src="assets/images/banners/banner_3.jpg"
                  alt="Banner Image"
                />
                <div class="banner-caption left-caption">
                  <h2>
                    {{ "Earrings" }} <small>{{ "Classic Appeal" }}</small>
                  </h2>
                </div>
                <!-- </a> -->
              </div>
            </figure>

            <figure class="banner-image mb0-image mt-image">
              <div class="captions">
                <!-- <a routerLink="/shop"> -->
                <img
                  class="img-fluid"
                  src="assets/images/banners/banner_5.jpg"
                  alt="Banner Image"
                />
                <div class="banner-caption left-caption">
                  <h2>
                    {{ "Watches" }}<small>{{ "Luxurious Timepieces" }}</small>
                  </h2>
                </div>
                <!-- </a> -->
              </div>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Products Tabs -->
<div class="pro-content pro-tab-content">
  <div class="container">
    <div class="products-area">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12">
          <div class="pro-heading-title">
            <h1>{{ "Unravel Timeless Beauty" }}</h1>
          </div>
        </div>
      </div>

      <!-- ..........tabs start ......... -->
      <div class="row">
        <div class="col-md-12 p-0">
          <!-- Tab panes -->
          <div class="tab-content">
            <div
              role="tabpanel"
              class="tab-pane fade active show"
              id="featured"
            >
              <!-- You can now use your library component in app.component.html -->
              <ngx-slick-carousel
                class="carousel"
                #slickModal="slick-carousel"
                [config]="slideConfigTabFeatured"
              >
                <div
                  ngxSlickItem
                  class="slide"
                  *ngFor="let product of products"
                >
                  <app-product-card [data]="product"></app-product-card>
                </div>

                <!-- 1st tab -->
              </ngx-slick-carousel>
            </div>

            <div role="tabpanel" class="tab-pane fade" id="special">
              <!-- You can now use your library component in app.component.html -->
              <ngx-slick-carousel
                class="carousel"
                #slickModal="slick-carousel"
                [config]="slideConfigTabSpecial"
              >
                <div
                  ngxSlickItem
                  class="slide"
                  *ngFor="let product of products"
                >
                  <app-product-card [data]="product"></app-product-card>
                </div>

                <!-- 1st tab -->
              </ngx-slick-carousel>
            </div>

            <div role="tabpanel" class="tab-pane fade" id="liked">
              <!-- You can now use your library component in app.component.html -->
              <ngx-slick-carousel
                class="carousel"
                #slickModal="slick-carousel"
                [config]="slideConfigTabLiked"
              >
                <div
                  ngxSlickItem
                  class="slide"
                  *ngFor="let product of products"
                >
                  <app-product-card [data]="product"></app-product-card>
                </div>

                <!-- 1st tab -->
              </ngx-slick-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Special Offer -->
<div class="pro-content pro-sp-content">
  <div class="container">
    <div class="products-area" *ngFor="let product of products | slice : 0 : 1">
      <div class="row align-items-center">
        <div class="col-12 col-lg-7 order-2 order-lg-1">
          <div class="pro-description">
            <span class="pro-info">
              {{ "Embrace Your Sparkling Story" }}
            </span>
            <h2 class="pro-title">
              {{ "Create Memories with Our Jewelry" }}
            </h2>
          </div>
        </div>
        <div class="col-12 col-lg-5 order-1 order-lg-2">
          <div class="pro-thumb">
            <!-- <a routerLink="/shop"> -->
            <span class="pro-image"
              ><img
                class="img-fluid"
                src="assets/images/product_images/deals_of_month.png"
                alt="Product Image"
            /></span>
            <!-- </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Popular Products -->
<div class="pro-content pro-pl-content">
  <div class="container">
    <div class="products-area">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12">
          <div class="pro-heading-title">
            <h2>{{ "Discover Our Newest Jewelry Pieces" }}</h2>
            <p>Browse our newest jewelry collection, crafted to impress you.</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 p-0">
          <ngx-slick-carousel
            class="carousel"
            #slickModal="slick-carousel"
            [config]="slidePopularConfig"
          >
            <div ngxSlickItem *ngFor="let product of products" class="slide">
              <app-popular-product
                [data]="product"
                class="popular-product"
              ></app-popular-product>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
</div>

<app-full-screen-banner></app-full-screen-banner>

<!-- Blogs -->
<div class="pro-content blog-content">
  <div class="container">
    <!-- heading -->

    <div class="row justify-content-center">
      <div class="col-12 col-lg-12">
        <div class="pro-heading-title">
          <h2>{{ "Latest Blog" }}</h2>
          <p>Expert insights on jewelry and how to maintain.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 p-0">
        <!-- <ngx-slick-carousel
          class="carousel"
          #slickModal="slick-carousel"
          [config]="slideBlogConfig"
        >
          <div ngxSlickItem *ngFor="let blog of blogs" class="slide">
            <app-blog-card-grid [data]="blog"></app-blog-card-grid>
          </div>
        </ngx-slick-carousel> -->
        <ngx-slick-carousel
          class="carousel"
          #slickModal="slick-carousel"
          [config]="{
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            arrows: true,
            dots: false
          }"
        >
          <div ngxSlickItem *ngFor="let blog of blogs" class="slide">
            <app-blog-card-grid [data]="blog"></app-blog-card-grid>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</div>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>

<app-product-modal></app-product-modal>
<app-back-to-top></app-back-to-top>
