<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- Site Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">{{ "Home" }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="Terms and Conditions">
          {{ "Terms and Conditions" }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content blog-content blog-content-page">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>Terms and Conditions</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="blog-area">
          <div class="row">
            <div class="col-12 col-sm-12">
              <div class="blog">
                <p class="text-wrap">
                  Welcome to Firartama, a jewelry-renting company based in Hong
                  Kong. The Terms and Conditions govern your use of our website
                  and the services we provide. By accessing or renting from our
                  website, you agree to these terms, which outline the rules and
                  responsibilities associated with our services. If you do not
                  agree with any part of these terms, you should refrain from
                  using our services.
                </p>
                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  1. Domestic Shipping Only
                </p>
                <p class="text-wrap">
                  Firartama only delivers jewelry within Hong Kong. We currently
                  do not offer international shipping. All orders are processed
                  and shipped to domestic addresses within Hong Kong.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  2. Payment Method
                </p>
                <p class="text-wrap">
                  We take only COD (cash on delivery) at this time, on our site.
                  You'll have to make the entire payment in cash at the delivery
                  point selected by you on the order, which has to reach that
                  delivery address also. Currently, we don't take any other kind
                  of payment.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  3. Product Information and Availability
                </p>
                <p class="text-wrap">
                  We make every effort to ensure that all product information
                  displayed on our website is accurate. However, some items may
                  be out of stock, and in some cases, products may be
                  unavailable or discontinued. Firartama reserves the right to
                  change or discontinue any product without notice.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  4. Order Processing and Delivery
                </p>
                <p class="text-wrap">
                  As soon as your order is received, we will process it
                  immediately. Domestic delivery is only provided to Hong Kong,
                  and deliveries take some time according to place and
                  availability. There is no additional cost for delivery as
                  applicable and you will see this in your check-out. We can
                  send it in the shortest possible time frame; however, it does
                  not depend on our external causes, so we do not account for
                  any delays in that matter.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  5. No Transfer of Personal Information to Third Parties
                </p>
                <p class="text-wrap">
                  At Firartama, we keep our customers' privacy. Under no
                  circumstances will we sell, share, or disclose your personal
                  data to third parties. All customer information is stored
                  securely and used only for processing orders, delivering
                  products, and customer service. Your trust is of great
                  importance to us, and your personal information is kept
                  confidential.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  6. Customer Responsibilities
                </p>
                <p class="text-wrap">
                  By accessing our website, you agree to provide accurate and
                  complete information when making a rent. You are responsible
                  for ensuring that the delivery address you provide is correct.
                  Firartama shall not be liable for any issues arising from the
                  provision of incorrect or incomplete address information.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  7. Limitation of Liability
                </p>
                <p class="text-wrap">
                  Firartama’s liability is limited to the value of the product
                  rented. We are not responsible for any direct, indirect,
                  incidental, or consequential damages resulting from the use or
                  inability to use the products rented from us.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  8. Modifications to Terms
                </p>
                <p class="text-wrap">
                  Firartama reserves the right to modify these Terms and
                  Conditions at any time. Any changes will be posted on our
                  website, and it is your responsibility to review these terms
                  regularly to stay informed of any updates.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  9. Governing Law
                </p>
                <p class="text-wrap">
                  These Terms and Conditions are governed by and construed in
                  accordance with the laws of Hong Kong. All disputes arising
                  out of, or related to, visiting or using our website or the
                  products shall be subject to the exclusive jurisdiction of the
                  courts in Hong Kong.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  10. Contact us
                </p>
                <p class="text-wrap">
                  For any further queries, please contact our customer support
                  executive at info&#64;firartama.net.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>
<app-back-to-top></app-back-to-top>
