import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
  //
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit() {
    this.setMetaTags();
  }

  setMetaTags() {
    const title = "Privacy Policy | Firartama";
    const description =
      "We give the highest priority to protecting the privacy of our website visitors. This policy outlines how we keep your personal information safe and secure.";

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: "description",
      content: description,
    });
  }
}
