<button
  class="btn dropdown-toggle"
  type="button"
  id="headerOneCartButton"
  data-toggle="dropdown"
  aria-haspopup="true"
  aria-expanded="false"
>
  <i class="fas fa-shopping-cart"></i>
  <span class="badge badge-secondary">{{ cartCount }}</span>
</button>
<div
  class="dropdown-menu dropdown-menu-right"
  aria-labelledby="headerOneCartButton"
>
  <ul class="shopping-cart-items" *ngIf="productAddedToCart.length != 0">
    <li *ngFor="let data of productAddedToCart">
      <div class="item-thumb">
        <div class="image">
          <img
            class="img-fluid"
            src="{{ data.products_image }}"
            alt="Product Image"
          />
        </div>
      </div>
      <div class="item-detail">
        <h2>{{ data.products_name }}</h2>
        <div class="item-s">
          {{ data.products_quantity }} x {{ data.products_price }}
          <i class="fas fa-trash" (click)="removeProduct(data)"></i>
        </div>
      </div>
    </li>

    <li>
      <span class="item-summary"
        >Total&nbsp;:&nbsp;<span>{{ productTotal }}</span>
      </span>
    </li>
    <li>
      <button class="btn btn-link btn-block" routerLink="/cart">
        {{ "View Cart" }}
      </button>
      <button
        class="btn btn-secondary btn-block swipe-to-top"
        routerLink="/checkout"
      >
        {{ "Checkout" }}
      </button>
    </li>
  </ul>

  <ul class="shopping-cart-items" *ngIf="productAddedToCart.length == 0">
    <li>
      {{ "Your cart is currently empty" }}
    </li>
  </ul>
</div>
