import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "src/app/app-routing.module";
import { TranslateModule } from "@ngx-translate/core";

import { ComponentsModule } from "../../components/components.module";
import { HeaderModule } from "../../layouts/header/header.module";
import { FooterModule } from "src/app/layouts/footer/footer.module";
import { SidebarModule } from "src/app/layouts/sidebar/sidebar.module";
import { WishlistComponent } from "./wishlist/wishlist.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
  declarations: [WishlistComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    ComponentsModule,
    HeaderModule,
    FooterModule,
    SidebarModule,
  ],
  exports: [WishlistComponent],
})
export class UserAccountModule {}
