<div class="pro-content pro-full-screen-banner">
  <div
    class="pro-full-bg"
    style="background-image: url(assets/images/banners/full_screen.jpg)"
  ></div>
  <div class="pro-full-screen-overlay">
    <div class="pro-full-screen">
      <div class="pro-heading-title">
        <h2>{{ "Crafted with Precision" }}</h2>
        <p>
          Each of our jewelry pieces is designed to provide outstanding quality
          and style.
        </p>
      </div>
      <a href="/shop">
        <button class="btn btn-secondary swipe-to-top">
          {{ "Shop Now" }}
        </button>
      </a>
    </div>
  </div>
</div>
