import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-header-sticky",
  templateUrl: "./header-sticky.component.html",
  styleUrls: ["./header-sticky.component.scss"],
})
export class HeaderStickyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
