<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- Site Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="'/'">{{ "Home" }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Blog" }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content blog-content blog-content-page">
  <div class="container" *ngFor="let blog of blogs">
    <div class="row" style="display: flex; justify-content: center">
      <div class="pro-heading-title">
        <h1>
          {{ blog.name }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="blog-area">
          <div class="row">
            <div class="col-12 col-sm-12">
              <div class="blog">
                <div class="blog-thumbnail">
                  <img
                    class="img-thumbnail"
                    src="{{ blog.image }}"
                    alt="Thumbnail"
                  />
                </div>
                <p class="text-wrap">
                  {{ blog.content }}
                </p>
                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  {{ blog.heading }}
                </p>
                <p class="text-wrap">
                  {{ blog.content2 }}
                </p>
                <p class="text-wrap">
                  {{ blog.content3 }}
                </p>
                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  {{ blog.heading2 }}
                </p>
                <p class="text-wrap">
                  {{ blog.content4 }}
                </p>
                <p class="text-wrap">
                  {{ blog.content5 }}
                </p>
                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  {{ blog.heading3 }}
                </p>
                <p class="text-wrap">
                  {{ blog.content6 }}
                </p>
                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  {{ blog.heading4 }}
                </p>
                <p class="text-wrap">
                  {{ blog.content7 }}
                </p>
                <p class="text-wrap">
                  {{ blog.content8 }}
                </p>
                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  {{ blog.heading5 }}
                </p>
                <p class="text-wrap">
                  {{ blog.content9 }}
                </p>
                <p class="text-wrap">
                  {{ blog.content10 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>
<app-back-to-top></app-back-to-top>>
