import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { KatoraService } from "../../services/katora.service";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
  //
})
export class CheckoutComponent implements OnInit {
  isSubmitting = false;
  alertVisible = false;
  successMessage = "Message sent successfully!";
  public name = "";
  public email = "";
  public phone = "";
  public subject = "";
  public msg = "";

  public productAddedToCart: any = [];
  public productTotal: any;

  constructor(private katoraService: KatoraService, private http: HttpClient) {}

  ngOnInit() {
    this.productAddedToCart = this.katoraService.getProductFromCart();
  }

  ngAfterContentChecked() {
    this.productAddedToCart = this.katoraService.getProductFromCart();
    this.productTotal = this.katoraService.calculateTotal().toFixed(2);
  }

  onSubmit(contactForm: NgForm) {
    if (contactForm.invalid) {
      // If the form is invalid, do not proceed and set the form controls as touched
      contactForm.controls["name"].markAsTouched();
      contactForm.controls["email"].markAsTouched();
      contactForm.controls["phone"].markAsTouched();
      contactForm.controls["subject"].markAsTouched();
      contactForm.controls["msg"].markAsTouched();
      return;
    } else {
      this.addShippingData();
    }

    this.isSubmitting = true;

    const formData = {
      name: contactForm.value.name,
      email: contactForm.value.email,
      phone: contactForm.value.phone,
      subject: contactForm.value.subject,
      msg: contactForm.value.msg,
    };

    this.http.post("https://acomal.top/send-mail/53/", formData).subscribe({
      next: (response) => {
        this.isSubmitting = false;
        this.alertVisible = true;
        // console.log("alertVisible: ", this.alertVisible); // Debug log

        contactForm.reset();
      },
      error: (error) => {
        console.error("Error sending form data:", error);
        this.isSubmitting = false;
        alert(
          "An error occurred while sending the message. Please try again later."
        );
      },
    });
  }

  addShippingData() {
    const formData = {
      name: this.name,
      email: this.email,
      phone: this.phone,
      subject: this.subject,
      msg: this.msg,
      products: this.productAddedToCart,
    };
    localStorage.setItem("orderData", JSON.stringify(formData));
  }
  closeAlert() {
    this.alertVisible = false;
  }
}
