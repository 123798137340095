<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">{{ "Home" }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Luxury Gold Watch" }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<!-- Site Content -->
<section class="pro-content product-content product-page-three">
  <div class="container">
    <div
      class="product-detail-info"
      *ngFor="let product of products | slice : 0 : 1"
    >
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12">
                  <div class="slider-wrapper">
                    <ngx-slick-carousel
                      #slickModal="slick-carousel"
                      [config]="slideProductConfig"
                      (init)="slickInit($event)"
                      class="carousel feedback slider-for"
                    >
                      <a
                        ngxSlickItem
                        class="slide slider-for__item fancybox-button"
                        *ngFor="
                          let image_gallerys of product.images;
                          let ind = index
                        "
                      >
                        <ngx-image-zoom
                          (click)="open(ind)"
                          [thumbImage]="image_gallerys.image"
                          [fullImage]="image_gallerys.image"
                        ></ngx-image-zoom>
                      </a>
                    </ngx-slick-carousel>

                    <ngx-slick-carousel
                      #slickModal="slick-carousel"
                      [config]="slideProductNavConfig"
                      (init)="slickInit($event)"
                      class="carousel thumbs"
                    >
                      <div
                        ngxSlickItem
                        class="slide slider-nav__item"
                        *ngFor="let image_gallerys of product.images"
                      >
                        <img
                          src="{{ image_gallerys.image }}"
                          alt="Zoom Image"
                        />
                      </div>
                    </ngx-slick-carousel>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12 col-md-12">
                  <h1 class="pro-title">{{ product.products_name }}</h1>

                  <div class="pro-price">
                    <!-- <del>$450</del>-->
                    <ins>HKD{{ product.products_price }}</ins>
                  </div>
                  <div class="pro-infos">
                    <div class="pro-single-info">
                      <b>{{ "Product ID" }} :</b>{{ product.products_id }}
                    </div>
                    <div class="pro-single-info">
                      <b>{{ "Available" }} :</b
                      ><span class="text-secondary">InStock</span>
                    </div>
                    <div class="pro-single-info">
                      <b>{{ "Min Order Limit" }} :</b>5
                    </div>
                  </div>

                  <div class="pro-counter">
                    <button
                      type="button"
                      class="btn btn-secondary btn-lg swipe-to-top"
                      (click)="onAddCart(product)"
                    >
                      Add to Cart
                    </button>
                  </div>
                  <div class="pro-sub-buttons">
                    <div class="buttons">
                      <button
                        (click)="onAddWishlist(product)"
                        type="button"
                        class="btn btn-link"
                      >
                        <i class="fas fa-heart"></i>{{ "Add to Wishlist" }}
                      </button>
                    </div>
                    <!-- AddToAny BEGIN -->
                    <div class="a2a_kit a2a_kit_size_32 a2a_default_style">
                      <a
                        class="a2a_dd"
                        href="https://www.addtoany.com/share"
                      ></a>
                      <a class="a2a_button_facebook"></a>
                      <a class="a2a_button_twitter"></a>
                      <a class="a2a_button_email"></a>
                    </div>
                    <script
                      async
                      src="https://static.addtoany.com/menu/page.js"
                    ></script>
                    <!-- AddToAny END -->
                  </div>

                  <div class="nav nav-pills" role="tablist">
                    <a
                      class="nav-link nav-item active"
                      href="#description"
                      id="description-tab"
                      data-toggle="pill"
                      role="tab"
                      style="cursor: default"
                      >{{ "Description" }}</a
                    >
                  </div>
                  <div class="tab-content">
                    <div
                      role="tabpanel"
                      class="tab-pane fade active show"
                      id="description"
                      aria-labelledby="description-tab"
                    >
                      {{ product.products_description }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>
<app-back-to-top></app-back-to-top>
