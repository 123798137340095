<nav id="headerOneNavbar" class="navbar navbar-expand-lg bg-nav-bar">
  <div class="navbar-collapse">
    <ul class="navbar-nav">
      <li class="nav-item dropdown">
        <a class="nav-link" routerLink="/">
          {{ "Home" }}
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link" routerLink="/about">
          {{ "About Us" }}
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link" routerLink="/shop">
          {{ "Shop" }}
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link" routerLink="/blogs">
          {{ "Blogs" }}
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link" routerLink="/contact">
          {{ "Contact Us" }}
        </a>
      </li>
    </ul>
  </div>
</nav>
