<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- empty Content -->
<section class="empty-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="pro-empty-page">
          <h2>{{ "Error 404..." }}</h2>
          <h1>{{ "Page Not Found" }}</h1>
          <p>
           The page you are trying to visit doesn't exist or has been removed. <a href="/" style="color: blue;">Go back</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>
<app-back-to-top></app-back-to-top>
