<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- Site Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">{{ "Home" }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{ "Blog" }}</li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content blog-content blog-content-page">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>
          {{ "Blog Page" }}
        </h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="blog-area">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6" *ngFor="let blog of blogs">
              <div class="blog">
                <div class="blog-thumbnail">
                  <img
                    class="img-thumbnail"
                    src="{{ blog.banner_img }}"
                    alt="Thumbnail"
                  />
                </div>

                <h4>
                  <a [routerLink]="['/' + blog.url]">{{ blog.name }}</a>
                </h4>
                <p>
                  {{ blog.short_content }}
                  <a [routerLink]="['/' + blog.url]"
                    ><br />{{ "Read More.." }}</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>
<app-back-to-top></app-back-to-top>
