import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(private translate: TranslateService, private router: Router) {
    translate.addLangs(["en", "ar"]);

    if (
      localStorage.getItem("lang") === null ||
      localStorage.getItem("lang") === undefined
    ) {
      localStorage.setItem("lang", "en");
      this.translate.use("en");
      translate.setDefaultLang("en");
    } else {
      translate.setDefaultLang(localStorage.getItem("lang"));
    }
  }
  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0); // Scroll to top
      }
    });
  }
}
