import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeOneComponent } from "./pages/home/home-one/home-one.component";
import { ShopOneComponent } from "./pages/shop/shop-one/shop-one.component";
import { ElegantDiamondNecklaceComponent } from "./pages/product-single/elegant-diamond-necklace/product-three.component";
import { ClassicGoldBandRingComponent } from "./pages/product-single/classic-gold-band-ring/product-three.component";
import { SterlingSilverCharmBraceletComponent } from "./pages/product-single/sterling-silver-charm-bracelet/product-three.component";
import { SapphireStudEarringsComponent } from "./pages/product-single/sapphire-stud-earrings/product-three.component";
import { LuxuryGoldWatchComponent } from "./pages/product-single/luxury-gold-watch/product-three.component";
import { VintagePearlBroochComponent } from "./pages/product-single/vintage-pearl-brooch/product-three.component";

import { CartOneComponent } from "./pages/cart/cart-one/cart-one.component";
import { CheckoutComponent } from "./pages/checkout/checkout.component";
import { WishlistComponent } from "./pages/user-account/wishlist/wishlist.component";

import { BlogOneComponent } from "./pages/blog/blog-one/blog-one.component";
import { BlogSingleComponent } from "./pages/blog/blog-single/blog-single.component";
import { BlogSingleTwoComponent } from "./pages/blog/blog-single-two/blog-single.component";
import { NotfoundComponent } from "./pages/notfound/notfound.component";

import { AboutTwoComponent } from "./pages/about/about-two/about-two.component";
import { ContactTwoComponent } from "./pages/about/contact-two/contact-two.component";
import { PrivacyPolicyComponent } from "./pages/about/privacy-policy/privacy-policy.component";
import { CookiesPolicyComponent } from "./pages/about/cookies-policy/privacy-policy.component";
import { RefundPolicyComponent } from "./pages/about/refund-policy/privacy-policy.component";
import { TermAndConditionComponent } from "./pages/about/term-and-condition/term-and-condition.component";

const routes: Routes = [
  { path: "", component: HomeOneComponent },
  { path: "about", component: AboutTwoComponent },
  { path: "shop", component: ShopOneComponent },
  {
    path: "elegant-diamond-necklace",
    component: ElegantDiamondNecklaceComponent,
  },
  {
    path: "classic-gold-band-ring",
    component: ClassicGoldBandRingComponent,
  },
  {
    path: "sterling-silver-charm-bracelet",
    component: SterlingSilverCharmBraceletComponent,
  },
  { path: "sapphire-stud-earrings", component: SapphireStudEarringsComponent },
  { path: "luxury-gold-watch", component: LuxuryGoldWatchComponent },
  { path: "vintage-pearl-brooch", component: VintagePearlBroochComponent },

  { path: "blogs", component: BlogOneComponent },
  {
    path: "preserving-your-jewelry-important-tips-for-lasting-beauty",
    component: BlogSingleComponent,
  },
  {
    path: "the-enduring-appeal-of-diamond-jewelry-a-timeless-beauty",
    component: BlogSingleTwoComponent,
  },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "cookies-policy", component: CookiesPolicyComponent },
  { path: "refund-policy", component: RefundPolicyComponent },
  { path: "terms-and-conditions", component: TermAndConditionComponent },
  { path: "contact", component: ContactTwoComponent },
  { path: "wishlist", component: WishlistComponent },
  { path: "checkout", component: CheckoutComponent },
  { path: "cart", component: CartOneComponent },

  { path: "**", component: NotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
