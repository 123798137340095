import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-term-and-condition",
  templateUrl: "./term-and-condition.component.html",
  styleUrls: ["./term-and-condition.component.scss"],
  //
})
export class TermAndConditionComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit() {
    this.setMetaTags();
  }

  setMetaTags() {
    const title = "Terms & Conditions | Firartama";
    const description =
      "Review this terms and conditions document to learn the particular terms associated with using our site and renting jewelry.";

    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: "description", content: description });
  }
}
