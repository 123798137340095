import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AppRoutingModule } from "src/app/app-routing.module";
import { TranslateModule } from "@ngx-translate/core";

import { ComponentsModule } from "../../components/components.module";
import { HeaderModule } from "../../layouts/header/header.module";
import { FooterModule } from "src/app/layouts/footer/footer.module";

import { SlickCarouselModule } from "ngx-slick-carousel";
import { ElegantDiamondNecklaceComponent } from "./elegant-diamond-necklace/product-three.component";
import { ClassicGoldBandRingComponent } from "./classic-gold-band-ring/product-three.component";
import { SterlingSilverCharmBraceletComponent } from "./sterling-silver-charm-bracelet/product-three.component";
import { SapphireStudEarringsComponent } from "./sapphire-stud-earrings/product-three.component";
import { LuxuryGoldWatchComponent } from "./luxury-gold-watch/product-three.component";
import { VintagePearlBroochComponent } from "./vintage-pearl-brooch/product-three.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxImageZoomModule } from "ngx-image-zoom";
import { LightboxModule } from "ngx-lightbox";

@NgModule({
  declarations: [
    ElegantDiamondNecklaceComponent,
    ClassicGoldBandRingComponent,
    SterlingSilverCharmBraceletComponent,
    SapphireStudEarringsComponent,
    VintagePearlBroochComponent,
    LuxuryGoldWatchComponent,
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgxImageZoomModule.forRoot(),
    LightboxModule,
    AppRoutingModule,
    TranslateModule,
    ComponentsModule,

    // Specify your library as an import
    SlickCarouselModule,

    HeaderModule,
    FooterModule,
  ],
  exports: [
    ElegantDiamondNecklaceComponent,
    ClassicGoldBandRingComponent,
    SterlingSilverCharmBraceletComponent,
    SapphireStudEarringsComponent,
    VintagePearlBroochComponent,
    LuxuryGoldWatchComponent,
  ],
})
export class ProductSingleModule {}
