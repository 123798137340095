<!-- Footer Mobile -->

<footer id="footerMobile" class="footer-area footer-mobile d-lg-none d-xl-none">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="single-footer">
          <div class="pro-about">
            <ul class="pl-0 mb-0">
              <li>
                <a href="/">
                  <app-logo></app-logo>
                </a>
              </li>
              <li>
                <span>
                  Stay up-to-date with new arrivals, exclusive offers, jewelry
                  trends, and more.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-2">
        <div class="single-footer">
          <h5>{{ "Quick Links" }}</h5>

          <ul class="pl-0 mb-0">
            <li>
              <a routerLink="/">{{ "Home" }}</a>
            </li>
            <li>
              <a routerLink="/blogs">{{ "Blog" }}</a>
            </li>
            <li>
              <a routerLink="/about">{{ "About Us" }}</a>
            </li>
            <li>
              <a routerLink="/contact">{{ "Contact Us" }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-2">
        <div class="single-footer">
          <h5>{{ "Our Links" }}</h5>

          <ul class="pl-0 mb-0">
            <li>
              <a routerLink="/cookies-policy">{{ "Cookies policy" }}</a>
            </li>
            <li>
              <a routerLink="/privacy-policy">{{ "Privacy Policy" }}</a>
            </li>
            <li>
              <a routerLink="/refund-policy">{{ "Refund Policy" }}</a>
            </li>
            <li>
              <a routerLink="/terms-and-conditions">{{
                "Terms & Conditions"
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-footer">
          <h5>{{ "Contact Us" }}</h5>

          <ul class="pl-0 mb-0">
            <li>
              <span
                ><i class="fas fa-map-marker-alt"></i> 44 Kimberley Rd, Tsim Sha
                Tsui, Hong Kong</span
              >
            </li>
            <li>
              <span><i class="fas fa-phone"></i> +85295711085</span>
            </li>
            <li>
              <span
                ><i class="fas fa-envelope"></i> info&#64;firartama.net
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid p-0">
    <div class="copyright-content">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-sm-12">
            <div class="footer-info">
              Copyright © 2024 <a href="/">Firartama</a>. All Right Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="mobile-overlay"></div>
