<!-- //Mobile Header -->
<header id="headerMobile" class="header-area header-mobile">
  <div class="header-maxi bg-header-bar">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-2 pr-0">
          <div class="navigation-mobile-container">
            <a href="javascript:void(0)" class="navigation-mobile-toggler">
              <span class="fas fa-bars"></span>
            </a>
            <nav id="navigation-mobile" style="padding: 20px 0px;">
              <div class="col-12 col-md-12 col-lg-2">
                <app-logo></app-logo>
              </div>
              <a
                class="main-manu btn"
                data-toggle="collapse"
                routerLink="/"
                role="button"
                aria-controls="shoppages"
              >
                {{ "Home" }}
              </a>
              <a
                class="main-manu btn"
                data-toggle="collapse"
                routerLink="/about"
                role="button"
                aria-controls="shoppages"
              >
                {{ "About Us" }}
              </a>
              <a
                class="main-manu btn"
                data-toggle="collapse"
                routerLink="/shop"
                role="button"
                aria-controls="shoppages"
              >
                {{ "Shop" }}
              </a>

              <a
                class="main-manu btn"
                data-toggle="collapse"
                routerLink="/blogs"
                role="button"
                aria-controls="shoppages"
              >
                {{ "Blogs" }}
              </a>

              <a
                class="main-manu btn"
                data-toggle="collapse"
                routerLink="/contact"
                role="button"
                aria-controls="shoppages"
              >
                {{ "Contact Us" }}
              </a>
            </nav>
          </div>
        </div>

        <div class="col-8">
          <app-logo></app-logo>
        </div>

        <div class="col-2 pl-0">
          <div class="dropdown">
            <app-cart-dropdown></app-cart-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
