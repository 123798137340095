import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-blog-single",
  templateUrl: "./blog-single.component.html",
  styleUrls: ["./blog-single.component.scss"],
  //
})
export class BlogSingleTwoComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit() {
    this.setMetaTags();
  }

  blogs = [
    {
      id: 1,
      post_type: "Featured post.",
      name: "The Enduring Appeal of Diamond Jewelry, A Timeless Beauty",
      banner_img: "assets/images/blogs/blog_post_2.jpg",
      image: "assets/images/blogs/blog_post_1_2.jpg",
      content:
        "There is always something special about a piece of diamond jewelry. Be it a pair of earrings, a necklace, or a ring, a diamond is always classy. There is perhaps no other stone or metal that looks more luxurious than a piece of diamond. With its own charm and beauty, a piece of diamond jewelry can leave everyone in awe. Besides, the elegance that a piece of diamond jewelry brings to a room is something that everyone admires. This appreciation and acknowledgment toward diamonds have been there for generations. Contrary to popular belief that diamond is a metal, it is actually a mineral. Diamonds come in different shapes and sizes; but, finding a high-quality and pure diamond is becoming more and more rare. This rarity feature of diamond is perhaps the reason why it is considered among the chief few expensive jewelry materials. Apart from their beauty and charm, a unique feature of diamonds is that they are among the hardest substances that don't break or even scratch that easily. In this blog, we will explore why diamond jewelry is a timeless beauty and will never go out of style.",
      heading: "Symbolizing Commitment and Love",
      content2:
        "In many families, diamond jewelry pieces have been there for many generations. This tells us about the enduring quality of diamonds. Diamonds have a deep connection with emotions. For centuries, gifting a diamond ring in an engagement has remained an unofficial ritual. This is because a diamond symbolizes love and a timeless bond. The timeless quality of diamonds makes them perfect to convey lifelong promises. That is perhaps the reason why, in events like weddings and anniversaries, diamonds are extremely popular. When it comes to emotional connection, diamonds go beyond just romantic relationships as they are gifted during celebrations of any milestones and achievements. With a piece of diamond jewelry, there is always a sentiment that works in the background, adding a layer of personal value. ",
      content3:
        "Diamonds are also perceived as a symbol of status in society. Such a perception has emerged from its historical significance. Since ancient times, diamonds have been used as special prizes by the royalties. This must have further cemented their status as a sign of sophistication and luxury. It is important to understand that apart from representing love and commitment, diamonds are also considered a symbol of trust, which can be linked with their enduring nature as diamonds don't break so easily.",
      heading2: "Sheer Brilliance and Versatility",
      content4:
        "Brilliance is one of the unique qualities of diamonds. They have this exquisite feature of refracting light, which makes an unrivaled sparkle that draws attention. A piece of diamond jewelry always catches the eyes of everyone around you, and its radiance guarantees it stands out in any type of event. Besides, diamonds are also known for their versatility. Diamond jewelry can complement any style, making them stand out among all other types of jewelry. The transparent, shiny, and bright appearance of diamonds goes with any kind of outfit. In addition to this, those who are accustomed to diamond jewelry know that diamonds come in a variety of designs. Each design has its unique ability to grab the attention of the crowd. Starting from princess cuts to heart-shaped diamonds, each shape gives different vibes when worn, symbolizing different emotions.",
      heading3: "Cultural Significance",
      content6:
        "Without a doubt, in many cultures across the world, diamonds have a profound cultural importance. These cultures often connect diamonds with strength, purity, and prosperity. Keeping these meanings in mind, diamond jewelry has been passed down through many generations. This makes diamonds a popular choice on occasions like anniversaries, weddings, and other special celebrations. Moreover, it is also seen that diamond jewelry is seldom cherished as a family heirloom. Their durability makes sure that they remain untainted across several generations, both emotionally and physically. In many Asian countries, diamonds are used as a gift for the bride, which is a big part of their rituals during the time of weddings.",
      heading4: "A Reflection of Eternal Elegance",
      content7:
        "We are living in a time when fashion trends change so rapidly that it is hard to keep up with them. However, regardless of the trends, diamond jewelry always goes with anything that further solidifies its brilliance. The classic appeal of diamond jewelry never feels out of place, irrespective of the era. Diamonds are loved by all generations and go with every style and outfit, ensuring their eternal elegance.",
      heading5: "The Artistry Behind It",
      content9:
        "Jewelers across the world agree that making a piece of diamond jewelry is one of the toughest jobs for them. Making a piece of flawless diamond jewelry takes time and outstanding craftsmanship. Only a proficient jeweler with precise skill can cut a diamond flawlessly and design it to perfection. Such commitment and skill level of a jeweler guarantees that each piece of jewelry is unique and cautiously crafted to portray the brilliance of the diamond. This indicates that owning a piece of diamond jewelry is not only about its visual appeal but also about acknowledging the expertise and effort that was put into creating that extraordinary piece.",
      content10:
        "Diamonds have always been a favorable choice for people who are attracted to their versatility, cultural importance, and beauty. Undoubtedly, even today, diamond jewelry continues to have a special place in the minds of jewelry lovers. One can confidently say that they will never go out of style.",
      short_content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
  ];

  setMetaTags() {
    if (this.blogs && this.blogs.length > 0) {
      const blog = this.blogs[0]; // Use the first blog or adjust as needed
      const title =
        "Timeless Elegance: Why Diamond Jewelry Endures | Firartama";
      const description =
        "Learn why diamond jewelry remains timeless, from its versatility to symbolism. Explore its cultural importance and enduring appeal across generations."; // Get the first 160 characters of content for the description

      this.titleService.setTitle(title);
      this.metaService.updateTag({ name: "description", content: description });
    }
  }
}
