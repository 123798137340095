<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">{{ "Home" }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "About Us" }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<!-- About-us Content -->
<section class="pro-content aboutus-content">
  <div class="container">
    <div class="row justify-content-center">
      <div class="pro-heading-title">
        <h1>
          {{ "About Firartama" }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12">
        <p class="peragraph">
          Located in Hong Kong, Firartama is a new-age online jewelry-renting
          platform, on a mission to impress all with its unique designs and
          one-of-a-kind attention to detail. Our team believes that jewelry is
          not only an accessory but also a style statement and expression of
          individuality. At Firartama, jewelry is all about timeless beauty and
          elegance. We started this journey with a desire to create pieces that
          portray the essence of extraordinary craftsmanship and meaningful
          design. Firartama offers a tailored collection of rings, necklaces,
          earrings, brooches, and watches to rent from. Each of these pieces is
          crafted cautiously with attention to detail. Our business takes pride
          in delivering premium-quality, gorgeous jewelry pieces that can be
          enjoyed for years to come. We are dedicated to providing a flawless
          online renting experience where every consumer feels appreciated and
          connected to the artistic quality behind each piece.
        </p>
      </div>
    </div>
  </div>

  <div class="container-fluid accordion-container">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-12">
          <!-- <div class="pro-heading-title">
            <h1>
              {{ "Our Offer" }}
            </h1>
          </div> -->
          <section class="accordions">
            <!--Accordion wrapper-->
            <div
              class="accordion md-accordion"
              id="accordionEx"
              role="tablist"
              aria-multiselectable="true"
            >
              <!-- Accordion card -->
              <div class="card">
                <!-- Card header -->
                <div class="card-header" role="tab" id="headingOne1">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseOne1"
                    aria-expanded="true"
                    aria-controls="collapseOne1"
                    class=""
                  >
                    <h5 class="mb-0">
                      {{ "Our Vision" }}
                    </h5>
                  </a>
                </div>

                <!-- Card body -->
                <div
                  id="collapseOne1"
                  class="collapse show"
                  role="tabpanel"
                  aria-labelledby="headingOne1"
                  data-parent="#accordionEx"
                  style=""
                >
                  <div class="card-body">
                    Firartama envisages becoming a reputed jewelry-renting
                    destination for those looking for timeless pieces that match
                    their exclusive style while facilitating a community of
                    inventiveness and craftsmanship. We aspire to encourage
                    self-expression and confidence in our customers.
                  </div>
                </div>
              </div>
              <!-- Accordion card -->

              <!-- Accordion card -->
              <div class="card">
                <!-- Card header -->
                <div class="card-header" role="tab" id="headingTwo2">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseTwo2"
                    aria-expanded="false"
                    aria-controls="collapseTwo2"
                  >
                    <h5 class="mb-0">
                      {{ "Our Mission" }}
                    </h5>
                  </a>
                </div>

                <!-- Card body -->
                <div
                  id="collapseTwo2"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo2"
                  data-parent="#accordionEx"
                >
                  <div class="card-body">
                    Firartama’s main mission is to bring elegance into daily life
                    with jewelry that represents sophistication, individuality,
                    and style, delivering outstanding quality and customer
                    satisfaction with every rent. Creativity is at the core of
                    our creations, making people happy.
                  </div>
                </div>
              </div>
              <!-- Accordion card -->

              <!-- Accordion card -->
              <div class="card">
                <!-- Card header -->
                <div class="card-header" role="tab" id="headingThree3">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseThree3"
                    aria-expanded="false"
                    aria-controls="collapseThree3"
                  >
                    <h5 class="mb-0">
                      {{ "Our Values" }}
                    </h5>
                  </a>
                </div>

                <!-- Card body -->
                <div
                  id="collapseThree3"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="headingThree3"
                  data-parent="#accordionEx"
                >
                  <div class="card-body">
                    We are committed to craftsmanship, integrity, quality, and
                    customer satisfaction. That's why Firartama is an expert in
                    delivering only the highest quality of jewelry pieces,
                    guaranteeing every piece exceeds your expectations with
                    style and elegance.
                  </div>
                </div>
              </div>
              <!-- Accordion card -->
            </div>
            <!-- Accordion wrapper -->
          </section>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>

<app-back-to-top></app-back-to-top>
