import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
  //
})
export class RefundPolicyComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit() {
    this.setMetaTags();
  }

  setMetaTags() {
    const title = "Refund Policy | Firartama";
    const description =
      "Review this refund policy of Firartama to know our procedure for handling refunds and guaranteeing a flawless experience for all consumers.";

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: "description",
      content: description,
    });
  }
}
