<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- Site Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">{{ "Home" }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="Refund Policy">
          {{ "Refund policy" }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content blog-content blog-content-page">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>Refund Policy</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="blog-area">
          <div class="row">
            <div class="col-12 col-sm-12">
              <div class="blog">
                <p class="text-wrap">
                  At Firartama, we do what we can to provide our valued
                  customers with a smooth renting experience in Hong Kong. We
                  know, however, that sometimes situations do arise in which you
                  would want to request a refund. Below, we provide guidelines
                  regarding our refund, cancellation, and return policies to
                  help ensure clarity and transparency.
                </p>
                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  Refund Process
                </p>
                <p class="text-wrap">
                  If for any reason you are not satisfied with your rent, we are
                  happy to process a refund. Please note that refunds are issued
                  only through a bank transfer. Should you want a refund, please
                  let us have your bank account details to enable us to affect
                  the transaction fast.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  Cancellation and Return Policy
                </p>
                <p class="text-wrap">
                  You can cancel your order within 24 hours of placing it. After
                  this window, cancellation will no longer be possible as the
                  order is considered final. If the product has already been
                  shipped or delivered, a return will be required. Returns must
                  be requested within 7 days from the date of receipt. All
                  returned items should be unused, in original packaging, and in
                  the same condition as when they were delivered.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  How to Request a Refund
                </p>
                <p class="text-wrap">
                  To request a refund, you can send a message to
                  info&#64;firartama.net with the subject line "Refund Request."
                  Please provide your order number and information about the
                  product to be returned. We will immediately send you
                  instructions on how to proceed regarding the process of
                  returning and receiving a refund.
                </p>
                <p class="text-wrap">
                  Processing the return and refund will take 7 to 10 business
                  days.
                </p>
                <p class="text-wrap">
                  Thank you for renting from us at Firartama. We appreciate your
                  trust and will try to make every experience a positive one. If
                  you have any questions, please feel free to contact us at
                  info&#64;firartama.net.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>
<app-back-to-top></app-back-to-top>
