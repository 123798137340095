import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "src/app/app-routing.module";
import { TranslateModule } from "@ngx-translate/core";

import { ComponentsModule } from "src/app/components/components.module";
import { HeaderModule } from "src/app/layouts/header/header.module";
import { FooterModule } from "src/app/layouts/footer/footer.module";

import { SlickCarouselModule } from "ngx-slick-carousel";

import { AboutTwoComponent } from "./about-two/about-two.component";
import { ContactTwoComponent } from "./contact-two/contact-two.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { CookiesPolicyComponent } from "./cookies-policy/privacy-policy.component";
import { TermAndConditionComponent } from "./term-and-condition/term-and-condition.component";
import { RefundPolicyComponent } from "./refund-policy/privacy-policy.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms"; // Import FormsModule
import { HttpClientModule } from "@angular/common/http";

@NgModule({
  declarations: [
    AboutTwoComponent,
    ContactTwoComponent,
    PrivacyPolicyComponent,
    TermAndConditionComponent,
    RefundPolicyComponent,
    CookiesPolicyComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    ComponentsModule,
    SlickCarouselModule,
    HeaderModule,
    FooterModule,
  ],
  exports: [
    AboutTwoComponent,
    ContactTwoComponent,
    PrivacyPolicyComponent,
    TermAndConditionComponent,
    RefundPolicyComponent,
  ],
  providers: [],
})
export class AboutModule {}
