<div class="product">
  <article>
    <div class="pro-icons mobile-pro-icons d-lg-none d-xl-none">
      <button (click)="onAddWishlist(data)" class="icon active swipe-to-top">
        <i class="fas fa-heart"></i>
      </button>
      <div
        class="icon swipe-to-top"
        data-toggle="modal"
        data-target="#quickViewModal"
      >
        <i class="fas fa-eye"></i>
      </div>
      <a routerLink="/compare" class="icon swipe-to-top"
        ><i class="fas fa-align-right" data-fa-transform="rotate-90"></i
      ></a>
    </div>

    <div class="pro-thumb">
      <a routerLink="data.url">
        <span class="pro-image"
          ><img
            class="img-fluid"
            src="{{ data.products_image }}"
            alt="Product Image"
        /></span>
        <span class="pro-image-hover swipe-to-top d-none d-lg-block d-xl-block"
          ><img
            class="img-fluid"
            src="{{ data.products_image }}"
            alt="Product Image"
        /></span>
      </a>
      <div class="pro-buttons d-none d-lg-block d-xl-block">
        <div class="pro-icons">
          <button
            (click)="onAddWishlist(data)"
            class="icon active swipe-to-top"
          >
            <i class="fas fa-heart"></i>
          </button>
        </div>

        <button
          type="button"
          class="btn btn-secondary btn-block swipe-to-top"
          (click)="onAddCart(data)"
        >
          {{ "Add to Cart" }}
        </button>
      </div>
    </div>
    <div class="pro-description">
      <span class="pro-info">
        {{ data.categories.categories_name }}
      </span>
      <h2 class="pro-title">
        <a [routerLink]="['/' + data.url]">{{ data.products_name }}</a>
      </h2>

      <div class="pro-price">
        <ins>HKD{{ data.products_price }}</ins>
      </div>

      <div class="list2">
        <div class="pro-options">
          <div class="color-selection">
            <h4>{{ "Color" }}: <span>Silver</span></h4>
            <ul>
              <li class="active">
                <a class="green" href="javascript:void(0);" tabindex="0"></a>
              </li>
              <li>
                <a class="red" href="javascript:void(0);" tabindex="0"></a>
              </li>
            </ul>
          </div>
          <div class="size-selection">
            <h4>{{ "Size" }}: <span>28</span></h4>
            <ul>
              <li class="active">
                <a href="javascript:void(0);" tabindex="0">28</a>
              </li>
              <li><a href="javascript:void(0);" tabindex="0">32</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="pro-mobile-buttons d-lg-none d-xl-none">
        <button
          type="button"
          class="btn btn-secondary btn-block swipe-to-top"
          (click)="onAddCart(data)"
        >
          {{ "Add to Cart" }}
        </button>
      </div>
    </div>
  </article>
</div>
