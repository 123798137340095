<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">{{ "Home" }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Contact Us" }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<!-- contact Content -->
<div class="container-fuild">
  <!-- Map here -->
  <!-- <div #mapContainer id="map"> -->
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.7123363982887!2d114.17422921385663!3d22.30080616690343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ee90645587%3A0xfd7439c31154df74!2s44%20Kimberley%20Road%2C%2044%20Kimberley%20Rd%2C%20Tsim%20Sha%20Tsui%2C%20Hong%20Kong!5e0!3m2!1sen!2sin!4v1733140013260!5m2!1sen!2sin"
    width="100%"
    height="400px"
    style="border: 0"
    allowfullscreen=""
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"
  >
  </iframe>
  <!-- </div> -->
</div>

<section class="pro-content contact-content contact-content-page">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <ul class="contact-info more-info pl-0 mb-0">
          <li>
            <h2>{{ "Address" }}</h2>
            <p>
              <span>
                <i
                  class="fas fa-map-marker-alt"
                  style="font-size: 0.9rem; width: 18px"
                ></i>
                44 Kimberley Rd, <br />Tsim Sha Tsui, Hong Kong
              </span>
            </p>
          </li>
          <li>
            <h2>{{ "Contact Info" }}</h2>
            <span>
              <i
                class="fas fa-phone"
                style="font-size: 0.9rem; width: 18px"
              ></i>
              +85295711085<br />
              <i
                class="fas fa-envelope"
                style="font-size: 0.9rem; width: 18px"
              ></i>
              info&#64;firartama.net
            </span>
          </li>

          <li class="pro-socials">
            <h2>
              {{ "Follow Us" }}
            </h2>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61569697448943"
                  target="_blank"
                  class="fab fb fa-facebook-square"
                ></a>
              </li>
              <li>
                <a
                  href="https://x.com/firartama"
                  target="_blank"
                  class="fab tw fa-twitter-square"
                ></a>
              </li>
              <li>
                <a
                  href="https://www.pinterest.com/firartama/"
                  target="_blank"
                  class="fab ln fa-pinterest"
                ></a>
              </li>
              <li>
                <a
                  href="https://www.tumblr.com/firartama/"
                  target="_blank"
                  class="fab ln fa-tumblr"
                ></a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@Firartama"
                  target="_blank"
                  class="fab ig fa-youtube"
                ></a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-8">
        <div class="row">
          <div class="pro-heading-title">
            <h1>
              {{ "Contact Us" }}
            </h1>
          </div>
        </div>
        <p>
          We are always ready to help you with anything related to renting
          products and associated services! Whether you have concerns or
          confusion about our jewelry pieces, require assistance with an order,
          or just want to give us feedback, our support team is here to help.
          Get in touch with us any time and our team will respond promptly.
          Firartama values every connection and looks forward to assisting you.
          Let’s make your jewelry-renting experience truly a special one.
        </p>
        <form
          #contactForm="ngForm"
          (ngSubmit)="onSubmit(contactForm)"
          id="theForm"
        >
          <div class="form-group row">
            <div class="col-sm-6">
              <input
                type="text"
                name="name"
                class="form-control"
                placeholder="Name"
                ngModel
                #name="ngModel"
                required
              />
              <div *ngIf="name.invalid && name.touched" class="text-danger">
                Name is required.
              </div>
            </div>
            <div class="col-sm-6">
              <input
                type="email"
                name="email"
                class="form-control"
                placeholder="Email"
                ngModel
                #email="ngModel"
                required
                email
              />
              <div *ngIf="email.invalid && email.touched" class="text-danger">
                Enter a valid email.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-6">
              <input
                type="text"
                name="phone"
                class="form-control"
                placeholder="Phone"
                ngModel
                #phone="ngModel"
                required
                pattern="^[0-9]{10}$"
              />
              <div *ngIf="phone.invalid && phone.touched" class="text-danger">
                Enter a valid phone number (10 digits).
              </div>
            </div>
            <div class="col-sm-6">
              <input
                type="text"
                name="subject"
                class="form-control"
                placeholder="Subject"
                ngModel
                #subject="ngModel"
                required
              />
              <div
                *ngIf="subject.invalid && subject.touched"
                class="text-danger"
              >
                Subject is required.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12">
              <textarea
                name="msg"
                class="form-control"
                placeholder="Message"
                rows="5"
                cols="56"
                ngModel
                #msg="ngModel"
                required
              ></textarea>
              <div *ngIf="msg.invalid && msg.touched" class="text-danger">
                Message is required.
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-secondary swipe-to-top"
            [disabled]="isSubmitting"
          >
            {{ isSubmitting ? "Loading..." : "Send" }}
          </button>

          <div
            *ngIf="alertVisible"
            class="alert alert-success mt-3"
            role="alert"
          >
            {{ successMessage }}
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="closeAlert()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>
<app-back-to-top></app-back-to-top>
