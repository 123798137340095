<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- checkout Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">{{ "Home" }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Checkout" }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content checkout-content">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>
          {{ "Checkout Page" }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-xl-9">
        <div class="row">
          <div class="checkout-module">
            <ul
              class="nav nav-pills checkoutd-nav mb-3"
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pills-shipping-tab"
                  data-toggle="pill"
                  href="#pills-shipping"
                  role="tab"
                  aria-controls="pills-shipping"
                  aria-selected="true"
                  ><span class="d-flex d-lg-none">1</span
                  ><span class="d-none d-lg-flex">{{
                    "Shipping Details"
                  }}</span></a
                >
              </li>
            </ul>
            <table class="table top-table">
              <thead>
                <tr class="d-flex">
                  <th class="col-12 col-md-2">{{ "ITEM(S)" }}</th>
                  <th class="col-12 col-md-4"></th>
                  <th class="col-12 col-md-2">{{ "PRICE (HKD)" }}</th>
                  <th class="col-12 col-md-1">{{ "QTY" }}</th>
                  <th
                    class="col-12 col-md-3"
                    style="display: flex; justify-content: center"
                  >
                    {{ "SUBTOTAL (HKD)" }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr class="d-flex" *ngFor="let data of productAddedToCart">
                  <td class="col-12 col-md-2">
                    <img
                      class="img-fluid"
                      src="{{ data.products_image }}"
                      alt="Product Image"
                    />
                  </td>
                  <td class="col-12 col-md-4">
                    <div class="item-detail">
                      <span class="pro-info">{{
                        data.categories.categories_name
                      }}</span>
                      <h2 class="pro-title">
                        {{ data.products_name }}
                      </h2>

                      <div class="item-attributes"></div>
                      <div class="item-controls">
                        <button type="button" class="btn" routerLink="/cart">
                          <span class="fas fa-pencil-alt"></span>
                        </button>
                      </div>
                    </div>
                  </td>
                  <td class="item-price col-12 col-md-2">
                    {{ data.products_price }}
                  </td>
                  <td class="col-12 col-md-2">
                    <div class="input-group">
                      {{ data.products_quantity }}
                    </div>
                  </td>
                  <td class="align-middle item-total col-12 col-md-2">
                    {{ data.products_price * data.products_quantity }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="tab-content">
              <div
                class="tab-pane fade show active"
                id="pills-shipping"
                role="tabpanel"
                aria-labelledby="pills-shipping-tab"
              >
                <form
                  #contactForm="ngForm"
                  (ngSubmit)="onSubmit(contactForm)"
                  id="theForm"
                >
                  <div class="form-group row">
                    <div class="col-sm-6">
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        placeholder="Name"
                        ngModel
                        #name="ngModel"
                        required
                      />
                      <div
                        *ngIf="name.invalid && name.touched"
                        class="text-danger"
                      >
                        Name is required.
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="Email"
                        ngModel
                        #email="ngModel"
                        required
                        email
                      />
                      <div
                        *ngIf="email.invalid && email.touched"
                        class="text-danger"
                      >
                        Enter a valid email.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-6">
                      <input
                        type="text"
                        name="phone"
                        class="form-control"
                        placeholder="Phone"
                        ngModel
                        #phone="ngModel"
                        required
                        pattern="^[0-9]{10}$"
                      />
                      <div
                        *ngIf="phone.invalid && phone.touched"
                        class="text-danger"
                      >
                        Enter a valid phone number (10 digits).
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <input
                        type="text"
                        name="subject"
                        class="form-control"
                        placeholder="Subject"
                        ngModel
                        #subject="ngModel"
                        required
                      />
                      <div
                        *ngIf="subject.invalid && subject.touched"
                        class="text-danger"
                      >
                        Subject is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <textarea
                        name="msg"
                        class="form-control"
                        placeholder="Message"
                        rows="5"
                        cols="56"
                        ngModel
                        #msg="ngModel"
                        required
                      ></textarea>
                      <div
                        *ngIf="msg.invalid && msg.touched"
                        class="text-danger"
                      >
                        Message is required.
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-secondary swipe-to-top"
                    [disabled]="isSubmitting"
                  >
                    {{ isSubmitting ? "Loading..." : "Send" }}
                  </button>

                  <div
                    *ngIf="alertVisible"
                    class="alert alert-success mt-3"
                    role="alert"
                  >
                    {{ successMessage }}
                    <button
                      type="button"
                      class="close"
                      aria-label="Close"
                      (click)="closeAlert()"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-3">
        <table class="table right-table">
          <thead>
            <tr>
              <th scope="col" colspan="2">{{ "Order Summary" }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{{ "Subtotal (HKD)" }}</th>
              <td>{{ productTotal }}</td>
            </tr>
            <tr class="item-price">
              <th scope="row">{{ "Total" }}</th>
              <td>{{ productTotal }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>
<app-back-to-top></app-back-to-top>
