import { Component, OnInit } from "@angular/core";
import { KatoraService } from "src/app/services/katora.service";
import { Lightbox } from "ngx-lightbox";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-product-three",
  templateUrl: "./product-three.component.html",
  styleUrls: ["./product-three.component.scss"],
})
export class VintagePearlBroochComponent implements OnInit {
  public productAddedToCart: any;
  public productTotal: any;

  constructor(
    private katoraService: KatoraService,
    private _lightbox: Lightbox,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.loadAlbum();
  }
  loadAlbum() {
    this.products[0].images.forEach((element) => {
      const src = element.image;
      const caption = "caption here";
      const thumb = element.image;
      const album = {
        src: src,
        caption: caption,
        thumb: thumb,
      };
      this._album.push(album);
    });
  }
  private _album = [];
  open(index: number): void {
    // open lightbox
    console.log("image");
    this._lightbox.open(this._album, index);
  }
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  onAddCart(product: any) {
    this.katoraService.addProductToCart(product);
  }
  onAddWishlist(product: any) {
    this.katoraService.addProductToWishlist(product);
  }

  ngOnInit() {
    this.productAddedToCart = this.katoraService.getProductFromCart();
    this.setMetaTags();
  }

  ngAfterContentChecked() {
    this.productAddedToCart = this.katoraService.getProductFromCart();
    this.productTotal = this.katoraService.calculateTotal().toFixed(2);
  }

  addQuantity(product: any) {
    this.katoraService.onAddQuantity(product);
  }
  removeQuantity(product: any) {
    this.katoraService.onRemoveQuantity(product);
  }

  products = [
    {
      server_time: 1576216365,
      url: "vintage-pearl-brooch",
      products_id: 6,
      products_quantity: 1,
      products_model: null,
      products_image: "assets/images/product_images/product_image_05.jpg",
      products_price: 81,
      products_date_added: "0000-00-00 00:00:00",
      products_last_modified: null,
      products_date_available: null,
      products_weight: "2000",
      products_weight_unit: "Gram",
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      is_new: 0,
      products_slug: "Vintage Pearl Brooch",
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 35,
      language_id: 1,
      products_name: "Vintage Pearl Brooch",
      products_description:
        "This Vintage Pearl Brooch is a classic jewelry item that accentuates your elegance and charm with any outfit. Highlighting a gorgeous-looking pearl surrounded by sophisticated detailing, this brooch is ideal for enhancing dresses, coats, or scarves. The timeless design of this jewelry item makes it an extraordinary option for formal occasions or as a collector’s piece.",
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: "20",
      currency: "usd",
      images: [
        {
          id: 1,
          products_id: 1,
          image: "assets/images/gallery/preview/Product_image_16.jpg",
          htmlcontent: null,
          sort_order: 1,
        },
        {
          id: 2,
          products_id: 1,
          image: "assets/images/gallery/preview/Product_image_17.jpg",
          htmlcontent: null,
          sort_order: 2,
        },
        {
          id: 3,
          products_id: 1,
          image: "assets/images/gallery/preview/Product_image_18.jpg",
          htmlcontent: null,
          sort_order: 3,
        },
      ],
      categories: [
        {
          categories_id: 18,
          categories_name: "Bracelet",
          categories_image: "83",
          categories_icon: "89",
          parent_id: 0,
        },
      ],
      rating: "0.00",
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: "0",
      attributes: [],
      quantity: 1,
    },
  ];

  slideProductConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    //"asNavFor": "slideProductNavConfig",
    asNavFor: ".thumbs",
  };

  slideProductNavConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".feedback",
    //"asNavFor": "slideProductConfig",
    centerMode: true,
    centerPadding: "60px",
    dots: true,
    arrows: false,
    focusOnSelect: true,
  };

  slideRelatedProductConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  slickInit(e) {
    console.log("slick initialized");
  }

  setMetaTags() {
    const title = "Vintage Pearl Brooch | Firartama";
    const description =
      "This Vintage Pearl Brooch adds charm to any outfit. An exclusive piece that evokes elegance and classiness.";

    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: "description", content: description });
  }
}
