import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-shop-one",
  templateUrl: "./shop-one.component.html",
  styleUrls: ["./shop-one.component.scss"],
})
export class ShopOneComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit() {
    this.setMetaTags();
  }

  products = [
    {
      server_time: 1576216365,
      url: "elegant-diamond-necklace",
      products_id: 1,
      products_quantity: 1,
      products_model: null,
      products_image: "assets/images/product_images/product_image_01.jpg",
      products_price: 285,
      products_date_added: "0000-00-00 00:00:00",
      products_last_modified: null,
      products_date_available: null,
      products_weight: "200",
      products_weight_unit: "Gram",
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 1,
      is_new: 1,
      products_slug: "Elegant Diamond Necklace",
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 53,
      language_id: 1,
      products_name: "Elegant Diamond Necklace",
      products_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      products_url: null,
      products_viewed: 0,
      products_left_banner: "",
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: "",
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: "50",
      currency: "usd",
      images: ["assets/images/product_images/product_image_01_02.jpg"],
      categories: [
        {
          categories_id: 14,
          categories_name: "Ring Collection",
          categories_image: "83",
          categories_icon: "83",
          parent_id: 0,
        },
      ],
      rating: "0.00",
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: "0",
      attributes: [],
      quantity: 1,
    },
    {
      server_time: 1576216365,
      url: "classic-gold-band-ring",
      products_id: 2,
      products_quantity: 1,
      products_model: null,
      products_image: "assets/images/product_images/product_image_02.jpg",
      products_price: 285,
      products_date_added: "0000-00-00 00:00:00",
      products_last_modified: null,
      products_date_available: null,
      products_weight: "200",
      products_weight_unit: "Gram",
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      is_new: 1,
      products_slug: "Classic Gold Band Ring",
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 51,
      language_id: 1,
      products_name: "Classic Gold Band Ring",
      products_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      products_url: null,
      products_viewed: 0,
      products_left_banner: "",
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: "",
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: "usd",
      images: ["assets/images/product_images/product_image_02_02.jpg"],
      categories: [
        {
          categories_id: 12,
          categories_name: "Earrings",
          categories_image: "83",
          categories_icon: "83",
          parent_id: 0,
        },
      ],
      rating: "0.00",
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: "0",
      attributes: [],
      quantity: 1,
    },
    {
      server_time: 1576216365,
      url: "sterling-silver-charm-bracelet",
      products_id: 3,
      products_quantity: 1,
      products_model: null,
      products_image: "assets/images/product_images/product_image_03.jpg",
      products_price: 85,
      products_date_added: "0000-00-00 00:00:00",
      products_last_modified: null,
      products_date_available: null,
      products_weight: "500",
      products_weight_unit: "Gram",
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      is_new: 0,
      products_slug: "Sterling Silver Charm Bracelet",
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 39,
      language_id: 1,
      products_name: "Sterling Silver Charm Bracelet",
      products_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.",
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: "usd",
      images: ["assets/images/product_images/product_image_03_02.jpg"],
      categories: [
        {
          categories_id: 11,
          categories_name: "Ring Collection",
          categories_image: "83",
          categories_icon: "83",
          parent_id: 0,
        },
      ],
      rating: "0.00",
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: "0",
      attributes: [],
      quantity: 1,
    },
    {
      server_time: 1576216365,
      url: "sapphire-stud-earrings",
      products_id: 4,
      products_quantity: 1,
      products_model: null,
      products_image: "assets/images/product_images/product_image_04.jpg",
      products_price: 110,
      products_date_added: "0000-00-00 00:00:00",
      products_last_modified: null,
      products_date_available: null,
      products_weight: "500",
      products_weight_unit: "Gram",
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      is_new: 0,
      products_slug: "Sapphire Stud Earrings",
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 37,
      language_id: 1,
      products_name: "Sapphire Stud Earrings",
      products_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.",
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: "100",
      currency: "usd",
      images: ["assets/images/product_images/product_image_04_02.jpg"],
      categories: [
        {
          categories_id: 11,
          categories_name: "Rings",
          categories_image: "83",
          categories_icon: "83",
          parent_id: 0,
        },
      ],
      rating: "0.00",
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: "0",
      attributes: [],
      quantity: 1,
    },
    {
      server_time: 1576216365,
      url: "luxury-gold-watch",
      products_id: 5,
      products_quantity: 1,
      products_model: null,
      products_image: "assets/images/product_images/product_image_06.jpg",
      products_price: 185,
      products_date_added: "0000-00-00 00:00:00",
      products_last_modified: null,
      products_date_available: null,
      products_weight: "300",
      products_weight_unit: "Gram",
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      is_new: 0,
      products_slug: "Luxury Gold Watch",
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 29,
      language_id: 1,
      products_name: "Luxury Gold Watch",
      products_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.",
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: null,
      currency: "usd",
      images: ["assets/images/product_images/product_image_06_02.jpg"],
      categories: [
        {
          categories_id: 15,
          categories_name: "Bangle",
          categories_image: "83",
          categories_icon: "83",
          parent_id: 0,
        },
      ],
      rating: "0.00",
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: "0",
      attributes: [],
      quantity: 1,
    },
    {
      server_time: 1576216365,
      url: "vintage-pearl-brooch",
      products_id: 6,
      products_quantity: 1,
      products_model: null,
      products_image: "assets/images/product_images/product_image_05.jpg",
      products_price: 81,
      products_date_added: "0000-00-00 00:00:00",
      products_last_modified: null,
      products_date_available: null,
      products_weight: "2000",
      products_weight_unit: "Gram",
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 0,
      is_new: 0,
      products_slug: "Vintage Pearl Brooch",
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 35,
      language_id: 1,
      products_name: "Vintage Pearl Brooch",
      products_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis felis tempus feugiat maximus. Aliquam erat volutpat. Aenean eget viverra mi. Duis pulvinar elit massa, vitae posuere urna blandit sed. Praesent ut dignissim risus.",
      products_url: null,
      products_viewed: 0,
      products_left_banner: null,
      products_left_banner_start_date: 0,
      products_left_banner_expire_date: 0,
      products_right_banner: null,
      products_right_banner_start_date: 0,
      products_right_banner_expire_date: 0,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: "20",
      currency: "usd",
      images: ["assets/images/product_images/product_image_05_02.jpg"],
      categories: [
        {
          categories_id: 18,
          categories_name: "Bracelet",
          categories_image: "83",
          categories_icon: "89",
          parent_id: 0,
        },
      ],
      rating: "0.00",
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: "0",
      attributes: [],
      quantity: 1,
    },
  ];

  setMetaTags() {
    const title = "Our Jewelry Collection | Firartama";
    const description =
      "Browse through our jewelry collection to select the one to rent that complements your style and personality, making you more elegant.";

    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: "description", content: description });
  }
}
