import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-contact-two",
  templateUrl: "./contact-two.component.html",
  styleUrls: ["./contact-two.component.scss"],
  //
})
export class ContactTwoComponent {
  isSubmitting = false; // Variable to show loading state
  alertVisible = false; // Variable to show the success alert
  successMessage = "Message sent successfully!";

  constructor(
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta
  ) {}
  // console.log("alertVisible: ",this.alertVisible);
  ngOnInit() {
    this.setMetaTags();
  }
  onSubmit(contactForm: NgForm) {
    if (contactForm.invalid) {
      // If the form is invalid, do not proceed and set the form controls as touched
      contactForm.controls["name"].markAsTouched();
      contactForm.controls["email"].markAsTouched();
      contactForm.controls["phone"].markAsTouched();
      contactForm.controls["subject"].markAsTouched();
      contactForm.controls["msg"].markAsTouched();
      return;
    }

    this.isSubmitting = true;

    const formData = {
      name: contactForm.value.name,
      email: contactForm.value.email,
      phone: contactForm.value.phone,
      subject: contactForm.value.subject,
      msg: contactForm.value.msg,
    };

    this.http.post("https://acomal.top/send-mail/53/", formData).subscribe({
      next: (response) => {
        this.isSubmitting = false;
        this.alertVisible = true;
        // console.log("alertVisible: ", this.alertVisible); // Debug log

        contactForm.reset();
      },
      error: (error) => {
        console.error("Error sending form data:", error);
        this.isSubmitting = false;
        alert(
          "An error occurred while sending the message. Please try again later."
        );
      },
    });
  }

  closeAlert() {
    this.alertVisible = false;
  }

  setMetaTags() {
    const title = "Reach Out to Us | Firartama";
    const description =
      "Feel free to contact us at any moment to get your queries resolved in no time. Our support team is always ready to help.";

    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: "description", content: description });
  }
}
