import { Component, OnInit } from "@angular/core";
import { KatoraService } from "src/app/services/katora.service";
import { Lightbox } from "ngx-lightbox";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-product-three",
  templateUrl: "./product-three.component.html",
  styleUrls: ["./product-three.component.scss"],
})
export class ElegantDiamondNecklaceComponent implements OnInit {
  public productAddedToCart: any;
  public productTotal: any;

  constructor(
    private katoraService: KatoraService,
    private _lightbox: Lightbox,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.loadAlbum();
  }
  loadAlbum() {
    this.products[0].images.forEach((element) => {
      const src = element.image;
      const caption = "caption here";
      const thumb = element.image;
      const album = {
        src: src,
        caption: caption,
        thumb: thumb,
      };
      this._album.push(album);
    });
  }
  private _album = [];
  open(index: number): void {
    // open lightbox
    console.log("image");
    this._lightbox.open(this._album, index);
  }
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  onAddCart(product: any) {
    this.katoraService.addProductToCart(product);
  }
  onAddWishlist(product: any) {
    this.katoraService.addProductToWishlist(product);
  }

  ngOnInit() {
    this.productAddedToCart = this.katoraService.getProductFromCart();
    this.setMetaTags();
  }

  ngAfterContentChecked() {
    this.productAddedToCart = this.katoraService.getProductFromCart();
    this.productTotal = this.katoraService.calculateTotal().toFixed(2);
  }

  addQuantity(product: any) {
    this.katoraService.onAddQuantity(product);
  }
  removeQuantity(product: any) {
    this.katoraService.onRemoveQuantity(product);
  }

  products = [
    {
      server_time: 1576216365,
      products_id: 1,
      products_quantity: 1,
      products_model: null,
      products_image: "assets/images/product_images/product_image_01.jpg",
      products_price: 285,
      products_date_added: "0000-00-00 00:00:00",
      products_last_modified: null,
      products_date_available: null,
      products_weight: "200",
      products_weight_unit: "Gram",
      products_status: 1,
      is_current: 1,
      products_tax_class_id: 0,
      manufacturers_id: null,
      products_ordered: 0,
      products_liked: 0,
      low_limit: 0,
      is_feature: 1,
      is_new: 1,
      products_slug: "Elegant Diamond Necklace",
      products_type: 0,
      products_min_order: 1,
      products_max_stock: 10,
      created_at: null,
      updated_at: null,
      id: 53,
      language_id: 1,
      products_name: "Elegant Diamond Necklace",
      products_description:
        "This gorgeous necklace highlights an out-of-the-world centerpiece set in premium-quality metal. Its sophisticated design adds a chic touch to any outfit, whether for a special event or everyday wear. This necklace, from the house of Firartama, symbolizes luxury, radiating timeless craftsmanship and beauty. To know more about this product, please contact us.",
      products_url: null,
      products_viewed: 0,
      products_left_banner: "",
      products_left_banner_start_date: null,
      products_left_banner_expire_date: null,
      products_right_banner: "",
      products_right_banner_start_date: null,
      products_right_banner_expire_date: null,
      manufacturer_name: null,
      manufacturer_image: null,
      manufacturers_slug: null,
      date_added: null,
      last_modified: null,
      manufacturers_image: null,
      manufacturers_url: null,
      discount_price: "50",
      currency: "usd",
      images: [
        {
          id: 1,
          products_id: 1,
          image: "assets/images/gallery/preview/Product_image_01.jpg",
          htmlcontent: null,
          sort_order: 1,
        },
        {
          id: 2,
          products_id: 1,
          image: "assets/images/gallery/preview/Product_image_02.jpg",
          htmlcontent: null,
          sort_order: 2,
        },
        {
          id: 3,
          products_id: 1,
          image: "assets/images/gallery/preview/Product_image_03.jpg",
          htmlcontent: null,
          sort_order: 3,
        },
      ],

      categories: [
        {
          categories_id: 14,
          categories_name: "Ring Collection",
          categories_image: "83",
          categories_icon: "83",
          parent_id: 0,
        },
      ],
      rating: "0.00",
      total_user_rated: 0,
      five_ratio: 0,
      four_ratio: 0,
      three_ratio: 0,
      two_ratio: 0,
      one_ratio: 0,
      reviewed_customers: [],
      defaultStock: 0,
      isLiked: "0",
      attributes: [],
      quantity: 1,
    },
  ];

  slideProductConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    //"asNavFor": "slideProductNavConfig",
    asNavFor: ".thumbs",
  };

  slideProductNavConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".feedback",
    //"asNavFor": "slideProductConfig",
    centerMode: true,
    centerPadding: "60px",
    dots: true,
    arrows: false,
    focusOnSelect: true,
  };

  slideRelatedProductConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  slickInit(e) {
    console.log("slick initialized");
  }

  setMetaTags() {
    const title = "Elegant Diamond Necklace | Firartama";
    const description =
      "Unravel the beauty of Firartama’s Elegant Diamond Necklace, an ageless piece designed to add brilliance and sophistication to your jewelry collection.";

    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: "description", content: description });
  }
}
