<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- Shop Page One content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink='/'>{{ "Home" }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{ "Shop" }}</li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content shop-content shop-special">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>
          {{ "Shop Page" }}
        </h1>
      </div>
    </div>
    <div class="products-area">
      <div class="row">
        <div id="swap" class="col-12 col-sm-12">
          <div class="row">
            <div
              class="col-12 col-sm-6 col-lg-4"
              *ngFor="let product of products"
            >
              <app-product-card [data]="product"></app-product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>

<app-product-modal></app-product-modal>
<app-back-to-top></app-back-to-top>
