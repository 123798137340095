<article>
  <div class="pro-thumb">
    <div class="pro-icons mobile-pro-icons d-lg-none d-xl-none">
      <button (click)="onAddWishlist(data)" class="icon active swipe-to-top">
        <i class="fas fa-heart"></i>
      </button>
    </div>
    <a [routerLink]="['/' + data.url]">
      <span class="pro-image">
        <img
          class="img-fluid"
          src="{{ data.products_image }}"
          alt="Product Image"
        />
      </span>
      <span class="pro-image-hover swipe-to-top">
        <img
          class="img-fluid"
          src="{{ data.products_image }}"
          alt="Product Image"
        />
      </span>
    </a>
    <div class="pro-buttons d-none d-lg-block d-xl-block">
      <div class="pro-icons">
        <button (click)="onAddWishlist(data)" class="icon active swipe-to-top">
          <i class="fas fa-heart"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="pro-description">
    <div>
      <span class="pro-info">
        {{ data.categories.categories_name }}
      </span>
      <h2 class="pro-title">
        <a [routerLink]="['/' + data.url]">{{ data.products_name }}</a>
      </h2>

      <div class="pro-price">
        <ins>HKD{{ data.products_price }}</ins>
      </div>

      <button
        type="button"
        class="btn btn-secondary btn-block swipe-to-top"
        (click)="onAddCart(data)"
      >
        Add to Cart
      </button>
    </div>
  </div>
</article>
