import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-about-two",
  templateUrl: "./about-two.component.html",
  styleUrls: ["./about-two.component.scss"],
  //
})
export class AboutTwoComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit() {
    this.setMetaTags();
  }
  slideTeamConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: false,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  slickInit(e) {
    console.log("slick initialized");
  }

  setMetaTags() {
    const title = "About Us | Firartama";
    const description =
      "Know how Firartama is dedicated to crafting exquisite jewelry pieces using gold, silver, diamond, and sapphire. Discover our story.";

    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: "description", content: description });
  }
}
