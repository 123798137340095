<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- Site Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">{{ "Home" }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="Cookies Policy">
          {{ "Cookies policy" }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content blog-content blog-content-page">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>Cookies Policy</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="blog-area">
          <div class="row">
            <div class="col-12 col-sm-12">
              <div class="blog">
                <p class="text-wrap">
                  Firartama respects your privacy and wants to make your online
                  renting experience with us as smooth as possible. This Cookies
                  Policy describes how we use cookies and similar technologies
                  on our website. Your continued use of our website implies that
                  you have read and accepted this Cookies Policy.
                </p>
                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  What Are Cookies?
                </p>
                <p class="text-wrap">
                  Cookies are small text files stored on your device (such as
                  your computer or mobile phone) when you visit a website. They
                  enable the website to recognize your device and collect
                  certain information to enhance your browsing experience.
                  Cookies can be used to remember your preferences, improve
                  website functionality, and provide personalized content and
                  advertisements.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  Types of Cookies We Use
                </p>
                <ul>
                  <li style="font-weight: bold">Essential Cookies</li>
                  <span
                    >These cookies are required for our website to work
                    properly. They allow you to move around, and use its
                    features such as making a rent or logging into secure areas,
                    etc. These services may not be available if such cookies are
                    disabled.</span
                  >
                  <li style="font-weight: bold">Performance Cookies</li>
                  <span
                    >These cookies allow us to collect information about how
                    people visit and use our website, including which pages are
                    frequently visited and any error messages received. All
                    these enable us to monitor how our website performs and to
                    make improvements as needed for a better user
                    experience.</span
                  >
                  <li style="font-weight: bold">Functionality Cookies</li>
                  <span
                    >These cookies allow us to remember your preferences and
                    choices, such as language selection, and give you a more
                    personalized experience. For example, if you add products to
                    your cart, these cookies ensure they remain there while you
                    continue browsing.</span
                  >
                  <li style="font-weight: bold">Advertising Cookies</li>
                  <span
                    >We do not utilize cookies for advertising purposes as we
                    are currently not involved in targeted advertising on the
                    website.</span
                  >
                </ul>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  Managing Cookies
                </p>
                <p class="text-wrap">
                  Many web browsers accept cookies automatically, but you are
                  able to manage or exercise control over them through settings
                  in your browser. Thus, you can enable or remove cookies at
                  will, even though this will impact user experience and the
                  functionality of our website.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  Third-Party Cookies
                </p>
                <p class="text-wrap">
                  We do not permit third-party advertisers to place cookies on
                  our website, nor do we share any personal data with third
                  parties for advertising purposes.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  Changes to This Cookie Policy
                </p>
                <p class="text-wrap">
                  We may modify this Cookies Policy from time to time. All
                  changes will be posted on this page with the date of the last
                  revision. Please check back periodically to be informed of any
                  changes to how we use cookies.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  Contact us
                </p>
                <p class="text-wrap">
                  If you have any questions or concerns about the use of
                  cookies, kindly write us at info&#64;firartama.net.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>
<app-back-to-top></app-back-to-top>
