<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- wishlist Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">{{ "Home" }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Wishlist" }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section
  class="pro-content wishlist-content"
  *ngIf="productAddedToWishlist.length != 0"
>
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>
          {{ "Wishlist Page" }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="row">
          <div class="col-12" *ngFor="let data of productAddedToWishlist">
            <div class="media">
              <div class="media-thumb">
                <img
                  class="img-fluid"
                  src="{{ data.products_image }}"
                  alt="Product Image"
                />
              </div>
              <div class="media-body">
                <div class="row">
                  <div class="col-12 col-md-8">
                    <h2>
                      <a routerLink="/product-one">{{ data.products_name }}</a>
                    </h2>
                    <div class="pro-price">
                      <ins>HKD {{ data.products_price }}</ins>
                    </div>
                    <p class="d-none d-lg-block d-xl-block">
                      {{ data.products_description }}
                    </p>
                    <div class="buttons">
                      <div class="input-group item-quantity">
                        <input
                          type="text"
                          name="quantity"
                          class="form-control"
                          value="{{ data.products_quantity }}"
                        />

                        <span class="input-group-btn">
                          <button
                            type="button"
                            (click)="addQuantity(data)"
                            class="quantity-plus btn"
                            data-type="plus"
                            data-field=""
                          >
                            <span class="fas fa-plus"></span>
                          </button>

                          <button
                            type="button"
                            [disabled]="data.products_quantity == 1"
                            (click)="removeQuantity(data)"
                            class="quantity-minus btn"
                            data-type="minus"
                            data-field=""
                          >
                            <span class="fas fa-minus"></span>
                          </button>
                        </span>
                      </div>
                      <button
                        (click)="onAddCart(data)"
                        class="btn btn-secondary swipe-to-top"
                      >
                        {{ "Add to Cart" }}
                      </button>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 detail">
                    <div class="share" style="cursor: pointer">
                      <a (click)="removeProduct(data)"
                        >{{ "Remove" }} &nbsp;<i class="fas fa-trash-alt"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="border-line" />
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<section class="empty-content" *ngIf="productAddedToWishlist.length == 0">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="pro-empty-page">
          <h2 style="font-size: 150px"><i class="fas fa-shopping-cart"></i></h2>
          <h1>{{ "Your Wishlist is empty" }}</h1>
          <p>
            <button class="btn btn-secondary" routerLink="/shop">
              {{ "Continue Shopping" }}
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>
<app-back-to-top></app-back-to-top>
