<!-- //Header Style One -->
<header id="headerOne" class="header-area header-one header-desktop">
  <div class="header-maxi bg-header-bar">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-12 col-lg-2">
          <app-logo></app-logo>
        </div>

        <div class="col-12 col-sm-8"></div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-2">
          <ul class="pro-header-right-options">
            <li>
              <app-wishlist-button></app-wishlist-button>
            </li>
            <li class="dropdown">
              <app-cart-dropdown></app-cart-dropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="header-navbar bg-menu-bar">
    <div class="container">
      <app-menu></app-menu>
    </div>
  </div>
</header>
