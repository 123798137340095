import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-blog-single",
  templateUrl: "./blog-single.component.html",
  styleUrls: ["./blog-single.component.scss"],
  //
})
export class BlogSingleComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit() {
    this.setMetaTags();
  }

  blogs = [
    {
      id: 1,
      post_type: "Featured post.",
      name: "Preserving Your Jewelry: Important Tips for Lasting Beauty",
      banner_img: "assets/images/blogs/blog_post_1.jpg",
      image: "assets/images/blogs/blog_post_1_1.jpg",
      content:
        "Do you still think jewelry is just a fashion accessory? If you do, then it's time you do some fact-checking and clear up this confusion. It doesn't matter what type of jewelry you own, there is much more to jewelry than just being a mere fashion ornament. Every piece of jewelry has some sort of sentimental value. Maybe it has been passed on for generations in a family, or a newly purchased piece for a special event, or just a simple one for everyday wear, but they all have some sentiments attached to them. In addition to this, jewelry pieces made from original metals and stones are also considered as appreciating assets. So, whether you have a stunning jewelry collection or own just a few pieces, their value is much more than one can think of. These factors make it necessary for individuals to properly maintain their jewelry pieces. In this article, we will explore some necessary tips that every jewelry-owning person should follow to ensure their pieces' lasting beauty.",
      heading: "Storing Them Properly",
      content2:
        "Storage is one of the key components that can ensure a jewelry's ideal maintenance. Jewelry owners often ignore this element as they think that protecting their pieces from getting stolen is more important than storing them in a proper place to guarantee their longevity and visual appeal. Since jewelry is used as one of the extremely important beautification elements of our own appearance, efficient preservation is important to retain their shine and structure. If a jewelry piece is not stored in proper condition, its shine might fade away, there could be dents and scratches, or even tarnishing can become a huge problem. An essential rule to follow for properly storing your jewelry pieces is not to keep them in the same place. They should always be stored separately from one another. This helps in avoiding any rubbing from each other; otherwise, every piece would start having scratches that would completely ruin its visual appeal. Following this rule is more important in the case of jewelry pieces with intricate designs and structures.",
      content3:
        "Every jewelry piece comes with its individual velvet-lined boxes when you buy them. It is beneficial to place every piece of jewelry in their own box after they have been used. The velvet lining protects the jewelry from attracting any scratches or distortion on its surface. These boxes also create a shield for the dust and moisture to get inside. Therefore, when it comes to storing a piece of jewelry properly, following these simple tips will help you for a long time.",
      heading2: "Regular Cleaning",
      content4:
        "Jewelry is an item that has both sentimental and materialistic value. While jewelry's sentimental value will always be there in your heart, to maintain its materialistic value one needs to ensure its proper upkeep. Regular cleaning is a way to make sure your jewelry retains its original beauty and appeal. It is normal that every piece of jewelry, with time, starts accumulating oil, dirt, and other impurities on the surface. But, with appropriate cleaning practices in place, one can ensure their jewelry's ideal condition. For pieces made from metals like gold or platinum, a soft microfiber cloth can be used on the surface gently to remove any impurities. Using a microfiber cloth will also guarantee that there are no scratches when the cleaning process ends.",
      content5:
        "However, in case of more stubborn stains, a mixture of mild soap and warm water can be used. Before starting to clean the jewelry with this solution, it is important to let the jewelry soak first in it. After a couple of minutes, the stains can be removed by gently brushing them. One can use a soft toothbrush for the optimal outcome. It is wise not to use any abrasive material as it can scratch the surface, further ruining the appearance of the jewelry piece. Besides, using any harsh chemical to clean jewelry made from gemstones can be a bad idea as they can completely damage the stone. Using a dry and soft cloth would be perfect for cleaning gemstone jewelry.  ",
      heading3: "Protecting from Different Elements",
      content6:
        "Saltwater and chlorine are two of the biggest enemies of your jewelry. They can damage any piece severely. That’s why it is essential to keep your jewelry away from these elements to ensure their lasting preservation. Even if your jewelry has come in touch with these elements, timely action can save the piece from getting tarnished or damaged. In such a situation, the ideal choice is to take your piece to the jeweler, who would know the proper way of getting rid of those elements from your jewelry surface. For jewelry pieces that are used every day, it is appropriate to remove them before using any body wash or applying perfume. When perfumes and body lotions come in contact with the metals or stones in a piece of jewelry, they start reacting, and it will damage your piece. These safeguarding tips can help anyone preserve their precious jewelry pieces for a long time.",
      heading4: "Checking for Loose Parts",
      content7:
        "Simple wear and tear are normal for jewelry. With time, some structures of your jewelry pieces may start loosening up. That's why keeping an eye on your jewelry collection every day is important as you may find some loose ends on them. When you discover them, you should get them checked at the nearest jewelry store and ask them to fix the issue. This highlights the importance of getting your jewelry pieces serviced every once in a while, by professional jewelers. Apart from any loose ends, a professional service can also help you fix any scratches by polishing them, which will make your jewelry pieces even glossier. Using the help of a professional jewelry service can strengthen the pieces’ preservation so that you can enjoy them for many years.",
      content8:
        "Looking after your jewelry pieces properly is important as it helps maintain their appearance and value, allowing them to continue shining brightly and be a treasured part of your precious collection.",
      short_content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
  ];

  setMetaTags() {
    if (this.blogs && this.blogs.length > 0) {
      const blog = this.blogs[0]; // Use the first blog or adjust as needed
      const title = "Jewelry Preservation Tips | Firartama";
      const description =
        "Through this article, learn about some vital tips to preserve your jewelry’s longevity and beauty. Explore how to clean, protect, and store your pieces.";

      this.titleService.setTitle(title);
      this.metaService.updateTag({ name: "description", content: description });
    }
  }
}
