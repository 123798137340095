<div class="pro-blog">
  <div class="pro-thumb">
    <img class="img-fluid swipe-to-top" src="{{ data.image }}" alt="Image" />
  </div>
  <div class="pro-detail">
    <h2>
      <a [routerLink]="['/' + data.url]">{{ data.name }}</a>
    </h2>

    <p>{{ data.short_content }}[...]</p>
    <a [routerLink]="['/' + data.url]" class="pro-readmore" tabindex="0">{{
      "Read more"
    }}</a>
  </div>
</div>
