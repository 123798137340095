<app-header-one></app-header-one>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- Site Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">{{ "Home" }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="Privacy Policy">
          {{ "Privacy Policy" }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content blog-content blog-content-page">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>Privacy Policy</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="blog-area">
          <div class="row">
            <div class="col-12 col-sm-12">
              <div class="blog">
                <p class="text-wrap">
                  Firartama respects your right to privacy and considers it
                  important. This Privacy Policy describes how we collect, use,
                  store, and protect your personal data when you visit our
                  website or engage with our services. We are committed to
                  ensuring your privacy and security in compliance with the
                  Personal Data (Privacy) Ordinance (PDPO) in Hong Kong.
                </p>
                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  1. Introduction
                </p>
                <p class="text-wrap">
                  Whenever we say "we," "us," or "our," we mean Firartama, a
                  Hong Kong-based jewelry-renting company. This Privacy Policy
                  shall apply to any data we collect from our website and
                  related services. By accessing and using our website, you
                  agree with the practices mentioned in this policy. In case you
                  disagree with the outlined policy, we request you not access
                  our services.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  2. Personal Data Defined
                </p>
                <p class="text-wrap">
                  Personal data means information that can be used to identify
                  you, such as your name, address, contact details, and payment
                  information. In relation to our website, we collect personal
                  data that can help facilitate your renting experience and
                  fulfill your orders when you interact with our website.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  3. Data We Collect
                </p>
                <p class="text-wrap" style="margin-bottom: 0">
                  We collect personal data that you provide directly to us when
                  making a rent, registering on our website, or contacting us
                  for customer service. This includes:
                </p>
                <ul>
                  <li>Name</li>
                  <li>Address (for delivery)</li>
                  <li>Email address</li>
                  <li>Contact number</li>
                  <li>Payment information (for cash on delivery)</li>
                </ul>
                <p class="text-wrap" style="margin-bottom: 0">
                  Any other information you provide as part of your
                  communication with our website
                </p>
                <p class="text-wrap">
                  We also collect aggregated non-personal information about your
                  IP address, browser type, and device for developing
                  improvements to our website's function and performance.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  4. Use of Your Personal Data
                </p>
                <p class="text-wrap" style="margin-bottom: 0">
                  We use your personal data to process orders, support
                  customers, and improve the renting experience. In detail, your
                  information may be used for:
                </p>
                <ul>
                  <li>Processing of orders and delivery</li>
                  <li>Addressing inquiries or service customer requests</li>
                  <li>
                    Sending order status updates and other important notices in
                    regard to our services
                  </li>
                  <li>
                    Improvement of our website and services on the basis of your
                    feedback
                  </li>
                </ul>
                <p class="text-wrap">
                  We use your information only for the purposes mentioned above
                  and do not use your data for marketing or for any other
                  purpose.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  5. Children's Privacy
                </p>
                <p class="text-wrap">
                  Our website is not meant for use by children under 13 years
                  old. We do not collect any kind of personal data from
                  children. If you are a parent or guardian and believe that we
                  have collected personal information from your child, please
                  contact us. We will delete that information as soon as
                  possible.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  6. Sharing Your Data
                </p>
                <p class="text-wrap">
                  Firartama is committed to your privacy. We will not share your
                  personal data with third parties unless required by law or as
                  necessary to process and fulfill your order. We do not sell,
                  rent, or lease your personal information to others. Your data
                  is only used to facilitate our services and ensure a smooth
                  transaction.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  7. Data Security
                </p>
                <p class="text-wrap">
                  We implement various security measures to protect your
                  personal data from unauthorized access, alteration,
                  disclosure, or destruction. However, no online system is 100%
                  secure, and while we strive to protect your data, we cannot
                  guarantee complete security.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  8. Compliance with PDPO
                </p>
                <p class="text-wrap">
                  Firartama respects the Personal Data (Privacy) Ordinance
                  (PDPO) in Hong Kong and processes your personal data in
                  compliance with the law. You may access, correct, or delete
                  your personal data anytime. If you want to exercise any of
                  these rights, please let us know.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  9. Retention of Data
                </p>
                <p class="text-wrap">
                  We retain your personal data for as long as is necessary to
                  fulfill the purposes set out in this Privacy Policy or for as
                  long as is required by law. We will delete or anonymize your
                  data once it is no longer needed.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  10. Changes to This Privacy Policy
                </p>
                <p class="text-wrap">
                  We may update or change this Privacy Policy at any time.
                  Changes will be posted on this page with an updated date.
                  Please check this page regularly to be better informed about
                  how we protect your privacy.
                </p>

                <p
                  class="text-wrap"
                  style="font-weight: bold; margin-bottom: 0"
                >
                  11. Contact Us
                </p>
                <p class="text-wrap">
                  If you have any questions or concerns about our Privacy
                  Policy, or if you would like to know more about your personal
                  data, please do not hesitate to reach out to us at
                  info&#64;firartama.net.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer-three></app-footer-three>
<app-back-to-top></app-back-to-top>
